<template>
  <DetailItemEditCard v-bind="$attrs" v-on="$listeners" :item-name="clientName" :promises.sync="detailItemEditCardPromises"
  :formPromises.sync="computedPromises" 
  :detailViewRouteLocation="{name: routeNames.CLIENT_DETAIL, params: {clientId: clientId}}">
    <template v-slot:form-fields>
      <slot name="form-fields"/>
    </template>
    <template v-slot:buttons-left>
      <slot name="buttons-left"/>
    </template>
    <template v-slot:save-button-content>
      <slot name="save-button-content"/>
    </template>
  </DetailItemEditCard>
</template>
  
  <script>
  import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue"
  import routeNames from "@/router/RouteNames";
  
  export default {
    name: "ClientDetailItemEditCard",
    props: {
      clientId: {
        type: String,
        required: true
      },
      promises: {
        default: () => []
      }
    },
    emits: ["update:promises"],
  
    data() {
      return {
        clientName: null,
        routeNames,
        detailItemEditCardPromises: [this.$store.dispatch('clientsModule/fetchClientName', this.clientId)
            .then((result) => this.clientName = result)]
      }
    },
  
    inheritAttrs: false,
    components: {
      DetailItemEditCard,
    },
    computed: {
      computedPromises: {
        get() {
          return this.promises
        },
        set(newValue) {
          this.$emit("update:promises", newValue)
        }
      }
    },
  }
  </script>