<template>
  <ClientDetailItemEditCard
    confirmation-message="Klantnaam werd succesvol gewijzigd"
                      title="Bewerk klantnaam"
                      update-store-action="clientsModule/updateClientName" :update-store-action-payload="{clientName: clientName, clientId: clientId}" 
                      :clientId="clientId" :promises.sync="promises">
      <template v-slot:form-fields>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="clientName" persistent-placeholder label="Titel" type="text" :rules="clientNameRules" required/>
            </v-col>
          </v-row>
      </template>
  </ClientDetailItemEditCard>
</template>

<script>
import ClientDetailItemEditCard from './ClientDetailItemEditCard.vue'

export default {
  name: "ClientDetailsEditClientName",
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      clientName: null,
      promises: [
        this.$store.dispatch('clientsModule/fetchClientName', this.clientId)
        .then((result) => {
          this.clientName = result
        })
      ],
      clientNameRules: [
        v => !!v || 'Klant naam moet ingevuld worden',
      ],
    }
  },
  components: {
    ClientDetailItemEditCard
  }
}
</script>